<template>
  <div
    v-if="course_item && course_item.course"
    class="course_item"
    @click="go_detail(course_item)"
  >
    <div
      class="title letterspace1"
      v-html="findWord(course_item.course.course_name)"
    ></div>
    <div class="label">
      <div class="one letterspace1">{{ course_item.course.course_tag }}</div>
      <!-- <div class="two">直播 <i></i>4课时 6人在学</div> -->
      <div class="two letterspace1">
        {{ course_item.course.course_class_hour }}课时
        {{ course_item.course.course_people }}人在学
      </div>
    </div>
    <div class="bottom_wrap">
      <div class="left_teacher">
        <template
          v-if="course_item.course.teacher && course_item.course.teacher.length"
        >
          <div
            class="item_teacher"
            v-for="(teacher, index) in course_item.course.teacher"
            :key="index"
          >
            <template v-if="index < 4">
              <img :src="teacher.teacher_avatar" alt="" />
              <div class="name">{{ teacher.teacher_name }}</div>
            </template>
          </div>
        </template>
      </div>
      <div class="price" v-if="course_item.course.course_price_type==1">
        {{
          course_item.course.course_present_price == 0
            ? "免费"
            : "¥" + course_item.course.course_present_price
        }}
      </div>
      <el-button v-else-if="course_item.course.course_price_type==2"
        type="danger"
        round
        plain
        size="small"
        >咨询</el-button
      >
    </div>
    <a ref="target" href="javascript:;" target="_blank"></a>
  </div>
</template>

<script>
import { compare_down } from "@/config/cookie.js";
export default {
  name: "course_item",
  props: {
    course_item: Object,
    word: String,
  },
  created() {
    if (this.course_item.course && this.course_item.course.teacher) {
      this.course_item.course.teacher.sort(compare_down("sort_id"));
    }
    // console.log(this.course_item)
  },
  methods: {
    go_detail(course_item) {
      //  let { href } = this.$router.resolve(
      //   `/course_info/${course_item.course.id}`
      // );
      // window.open(href, "_blank");
      if(course_item.course.activity){
        let target = this.$refs.target;
        target.setAttribute("href", `${course_item.course.activity.h5_url}`);
        target.click();
        return
      }

      let target = this.$refs.target;
      target.setAttribute("href", `/course_info/${course_item.course.id}`);
      target.click();
    },
    findWord(val) {
      if (this.word) {
        // let rega = "/" + this.word + "/";
        // console.log(rega)
        // let rega = new RegExp(this.word, 'ig')
        // return val.replaceAll(rega,
        //   "<span class='word_style'>" + this.word + "</span>"
        // );
        let allVal = val.match(new RegExp(this.word, "ig"));
        if (allVal) {
          for (var j = 0; j < allVal.length; j++) {
            val = val.replace(allVal[j], "[*" + j + "*]");
          }
          for (var i = 0; i < allVal.length; i++) {
            val = val.replace(
              "[*" + i + "*]",
              '<span class="word_style">' + allVal[i] + "</span>"
            );
          }
        }
        return val
      } else {
        return val;
      }
    },
  },
  filters: {},
};
</script>
<style>
.word_style {
  color: #409eff;
  zoom: 1.2;
}
.course_item:hover {
  /*   // box-shadow: 0 0 10px #dce6f1;
  // box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, .08); */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
}
.course_item {
  cursor: pointer;
  transition: all 0.4s;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.course_item {
  width: 282px;
  height: 210px;
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  position: relative;
  transition: all 0.4s;
  cursor: pointer;

  .img_bg {
    width: 282px;
    height: 210px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .title {
    font-size: 16px;
    font-weight: 700;
    color: #212838;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    word-break: break-all;
  }
  .label {
    display: flex;
    align-items: center;
    margin-top: 15px;

    .one {
      width: fit-content;
      border: 1px solid #00c87f;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 400;
      color: #00c87f;
      padding: 0px 3px;
      margin-right: 5px;
    }
    .two {
      // margin-top: 8px;
      // font-size: 14px;
      // font-weight: 400;
      // color: #666666;
      margin-left: 10px;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      color: #4f5666;
      line-height: 12px;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      // margin-top: 13px;

      i {
        width: 2px;
        height: 2px;
        background: #666666;
        display: inline-block;
        margin: 0 6px;
      }
    }
  }
  .bottom_wrap {
    width: 247px;
    position: absolute;
    bottom: 20px;
    left: 22px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 40px;
    .left_teacher {
      display: flex;
      align-items: center;
      // width: 152px;
      flex: 1;
      overflow: hidden;
      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        // margin-right: 10px;
      }
      .item_teacher {
        // width: 50px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 4px;
        .name {
          // width: 60px;
          margin-top: 7px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .price {
      font-size: 18px;
      font-weight: 600;
      color: #f96158;
      // width: 150px;
      text-align: right;
    }
  }
}
</style>
