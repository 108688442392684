<template>
  <div class="course_list">
    <div class="zhanwei">
      <!-- 占位 -->
    </div>
    <div class="connent_wrap container">
      <tab :tab_list="tab_list" @tab_index="tab_index" :column_id="column_id"/>
        <div class="course_content" v-show="show">
          <div v-if="tab_course.length" class="course_box">
            <course_item
              :course_item="item"
              v-for="(item) in tab_course"
              :key="item.id"
            />
          </div>
          <div v-else class="nocontent">
            <img
              class="ma"
              src="@/assets/common/nocontent.png"
              mode="widthFix"
            />
            <p>暂无内容</p>
          </div>
        </div>

      <div class="page_wrap">
        <el-pagination
          @current-change="handleCurrentChange"
          @prev-click="prev_click"
          @next-click="next_click"
          layout="total, prev, pager, next, jumper"
          background
          :current-page="page"
          :page-size="limit"
          :total="total"
          :hide-on-single-page="true"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import course_item from "@/components/course_item.vue";
import tab from "@/components/tab.vue";
import api from "@/config/api.js";
import { mapActions } from "vuex";
export default {
  name: "course_list",
  components: {
    course_item,
    tab,
  },
  data() {
    return {
      tab_list: [],
      activeName: "",
      tab_course: [],
      column_id: null,
      page: 1,
      limit: 16,
      total: 1,
      show: true,
    };
  },
  mounted() {},
  created() {
    this.setColumn();
    const { column } = this.$store.state;
    this.tab_list = column;

    if (this.$route.params.id) {
      this.column_id = this.$route.params.id;
    } else {
      this.column_id = column[0].id;
    }

    this.getData();
  },
  methods: {
    ...mapActions(["setColumn"]),
    async getData() {
      try {
        const res = await api.all_course({
          column_id: this.column_id,
          page: this.page,
          limit: this.limit,
        });
        if (res.code == 0) {
          let data = res.result.data;
          this.tab_course = data;
          this.total = res.result.count;
        } else {
          this.$message(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    tab_index(id) {
      if(this.column_id!=id){
        this.column_id = id;
        this.page = 1;
        this.getData();
      }
      
    },
    handleCurrentChange(e) {
      this.page = e;
      this.getData();
    },
    prev_click(e) {
      this.page = e;
      this.getData();
    },
    next_click(e) {
      this.page = e;
      this.getData();
    },
  },
  watch:{
  }
};
</script>
<style>
.course_list .tab_wrap .tab_ .li {
  margin-right: 38px !important;
}
</style>
<style lang="less" scoped>
.course_list {
  background-color: #f9fbfd;
  // min-height: calc(100vh - 451px);
  min-height: 100vh;

  // min-height: calc(100vh - 551px);
  padding-bottom: 25px;
  .zhanwei {
    height: 25px;
  }
  .course_content {
    // min-height: 400px;
    min-height: 700px;
    position: relative;
  }
  .course_box {
    margin-top: 20px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    flex-wrap: wrap;
  }
  .course_item {
    margin-right: 24px;
    margin-bottom: 30px;
  }
  .course_item:nth-of-type(4n) {
    margin-right: 0;
  }
}
/deep/.el-pagination.is-background .el-pager li {
  background-color: #f4f4f5 !important;
  border-radius: 2px !important;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #409eff !important;
}
.page_wrap {
  margin-bottom: 15px;
}
</style>